import React, {useEffect, useState} from 'react';
import FeatherIcon from 'feather-icons-react';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import classNames from 'classnames';

// component
import Menu from './Menu';

// images
import logo from 'assets/images/eLogo330_230.png';
import { useSelector } from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import {searchVendor} from "../../config/Api/meilisearchApi";
import {debounce} from "../../util/timeCommon";
import Select from "react-select";
import {Search} from "../../interfaces/Search";
import {Vendor} from "../../interfaces/vendor";
import AsyncSelect from "react-select/async";
import {Option} from "../../interfaces/Option";
import {decryptObject, encryptObject} from "../../pages/landings/App/common";

type Navbar2Props = {
    isSticky?: boolean;
    navClass?: string;
    buttonClass?: string;
    fixedWidth?: boolean;
    hideSearch?: boolean;
    user?: any;
};

const Navbar2 = ({ isSticky, navClass, buttonClass, fixedWidth, hideSearch, user }: Navbar2Props) => {
    const currentUser = useSelector((state: any) => state.auth.user);
    const [text, setText] = useState('');
    const [hits, setHits] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (value: string) => {
        setLoading(true);
        const results: Search<Vendor> = await searchVendor(value);
        const mapHits = results.hits?.filter(store => store.isVerified).map(item => {
            const name = item.title || item.name;
            const address = item.address;
            const city = address.city;
            const state = address.state;
            const zip = address.postalCode;
            const combineAddress = `${name} - (${city}, ${state} - ${zip})`;
            return {label: combineAddress, value: item.id}
        })
        setHits(mapHits ?? []);
        setLoading(false);
    }

    const debounceSearch = debounce(handleSearch, 300);

    const onChangeText = (value: string, action: any) => {
        if (action?.action !== 'input-blur' && action?.action !== 'menu-close') {
            setText(value);
            debounceSearch(value);
        }
    };

    const onChangeValue = (option: Option) => {
        if (option.value) {
            const encryptedValue = encryptObject(option.value).replaceAll('/', '-')
            window.location.href = `/store/${encryptedValue}`;
        }
    }

    // on scroll add navbar class and back to top button
    useEffect(() => {
        const btnTop = document.getElementById('btn-back-to-top');
        const navbar = document.getElementById('sticky');
        window.addEventListener('scroll', (e) => {
            e.preventDefault();
            if (btnTop) {
                if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
                    btnTop.classList.add('show');
                } else {
                    btnTop.classList.remove('show');
                }
            }
            if (navbar) {
                if (document.body.scrollTop >= 240 || document.documentElement.scrollTop >= 240) {
                    navbar.classList.add('navbar-sticky');
                } else {
                    navbar.classList.remove('navbar-sticky');
                }
            }
        });
    }, []);

    return (
        <header>
            <Navbar
                id={isSticky ? 'sticky' : ''}
                collapseOnSelect
                expand="lg"
                className={classNames('topnav-menu', navClass)}
            >
                <Container fluid={!fixedWidth}>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Brand href="/" className="hide-logo me-lg-4 me-auto">
                        <img src={logo} height="50" className="d-inline-block align-top" alt="" />
                    </Navbar.Brand>
                    {/* TODO: Uncomment this once login is back */}
                    {/* {!hideSearch && (
                        <div className="search-width">
                            <Select
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                placeholder='Search...'
                                onInputChange={onChangeText}
                                options={hits}
                                inputValue={text}
                                noOptionsMessage={() => 'No Results Found'}
                                isLoading={loading}
                                onChange={onChangeValue}
                            />
                        </div>
                    )} */}
                    {/* <Navbar.Collapse id="responsive-navbar-nav">
                        <Menu navClass="mx-auto" loggedInUser={currentUser} />
                    </Navbar.Collapse>
                    <Nav as="ul" className="align-items-lg-center">
                        {user ? (
                            <Row className="align-items-lg-center">
                                <Col xs={"auto"}>
                                    <NavLink
                                        to="/auth/logout"
                                        className={classNames('btn', buttonClass ? buttonClass : 'btn-soft-primary')}
                                    >
                                        Logout
                                    </NavLink>
                                </Col>
                            </Row>
                        ) : (
                            <Row className="align-items-lg-center">
                                <Col xs={"auto"}>
                                    <NavLink
                                        style={{ margin: 5 }}
                                        to="/auth/login"
                                        className={classNames('btn', buttonClass ? buttonClass : 'btn-soft-primary')}
                                    >
                                        <FeatherIcon icon="user" className="text-white" size={25} />
                                    </NavLink>
                                </Col>
                            </Row>
                        )}
                    </Nav> */}
                </Container>
            </Navbar>
        </header>
    );
};

export default Navbar2;
